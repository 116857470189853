export default function trustarc() {
  return {
    init() {
    this.$el.innerHTML = this.template;

    var tascript_1 = document.createElement("script");
    tascript_1.src = "https://consent.trustarc.com/notice?domain=northeastern.edu&c=teconsent&js=nj&noticeType=bb&text=true&gtm=1&pcookie&privacypolicylink=https%3A%2F%2Fwww.northeastern.edu%2Fprivacy-information%2F";
    tascript_1.setAttribute("async", "async");
    this.$el.appendChild( tascript_1 );
    },
    template: `
    <!--TrustArc Code-->
    <div class="nu-global">
    <footer class="md:-mt-4 pt-0 pb-6 text-center md:text-left text-gray-300 text-xs font-sans antialiased bg-black" aria-label="cookie manager">
    <div id="consent_blackbar"></div>
    <div id="teconsent" class="px-4 lg:px-16"></div> 
    </footer>
    </div>
    <!--End TrustArc Code-->`,
  }
}
