import { createClient } from 'contentful/dist/contentful.browser.min.js'
import { getTemplate } from '../util/getTemplate'

export default function header(options = {}) {
  return {
    options: {
      wordmark: false,
      skipToMainSelector: null,
      menu: true,
      ...options,
    },
    client: null,
    navIsOpen: false,
    navSections: [],
    openSection: null,
    featuredLinks: [],
    pinnedLink: null,
    template: '',
    init() {
      this.$el.removeAttribute('style')

      this.client = createClient({
        space: '8276ycqoicis',
        environment: 'master',
        accessToken: 'A_Rrll-l9VF_CYkRwllZIxsp3GcISsXIpgiSeh7UDno',
        timeout: 3000,
      })

      this.getCachedData()

      this.updateData()

      this.getTemplate()

      this.$el.innerHTML = this.template

      this.$watch('navIsOpen', (val) => {
        if (val) {
          document.body.style.overflowY = 'hidden'
        } else {
          document.body.style.overflowY = 'initial'
        }
      })

      document.addEventListener('keydown', (e) => {
        if (e.key == 'Escape') {
          this.navIsOpen = false
        }

        if (e.key == 'Tab' || e.key == 9) {
          const menu = document.getElementById('nu-global-header-menu');
          
          setTimeout(() => {
            if (!this.isMenuFocused(menu)) {
              this.navIsOpen = false
            }
          }, 75)
        }
      })
    },
    getCachedData() {
      if (!localStorage) {
        return
      }

      const sections = localStorage.getItem('nu-global-header-sections')

      if (sections) {
        this.navSections = JSON.parse(sections)
      }

      const featuredLinks = localStorage.getItem('nu-global-header-featured-links')

      if (featuredLinks) {
        this.featuredLinks = JSON.parse(featuredLinks)
      }

      const pinnedLink = localStorage.getItem('nu-global-header-pinned-link')

      if (pinnedLink) {
        this.pinnedLink = JSON.parse(pinnedLink)
      }
    },
    updateData() {
      if (this.dataIsAlreadyCached()) {
        return
      }

      Promise.all(
        [
          this.getNavSections(), 
          this.getFeaturedLinks()
        ]
      ).then(() => {
        this.cacheData()
      })
    },
    dataIsAlreadyCached() {
      const cachedAt = localStorage.getItem('nu-global-header-cached-at')

      if (!cachedAt) {
        return false
      }

      const cacheFor = 10 * 60 * 1000 // 10 minutes

      return Date.now() - cachedAt < cacheFor
    },
    isMenuFocused(node) {
      return node.contains(document.activeElement)
    },
    getTemplate() {
      this.template = getTemplate('header')
    },
    getNavSections() {
      return this.client
        .getEntries({
          content_type: 'globalHeaderSection',
          order: 'fields.order',
        })
        .then((entries) => {
          this.navSections = entries.items.map(({ sys, fields }) => {
            return {
              id: sys.id,
              title: fields.title,
              order: fields.order,
              links: fields.links.map(({ text, url }) => {
                return {
                  text,
                  url,
                }
              }),
            }
          })
        })
    },
    getFeaturedLinks() {
      return this.client
        .getEntries({
          content_type: 'globalHeaderFeaturedLink',
          order: 'fields.order',
        })
        .then((entries) => {
          const links = entries.items.map(({ sys, fields }) => {
            return {
              id: sys.id,
              text: fields.text,
              order: fields.order,
              url: fields.url,
              pinToBottom: fields.pinToBottom,
            }
          })

          this.featuredLinks = links.filter((l) => !l.pinToBottom)

          if (links.filter((l) => l.pinToBottom).length) {
            this.pinnedLink = links.filter((l) => l.pinToBottom)[0]
          }
        })
    },
    cacheData() {
      if (!localStorage) {
        return
      }

      localStorage.setItem('nu-global-header-cached-at', Date.now().toString())

      localStorage.setItem(
        'nu-global-header-sections',
        JSON.stringify(this.navSections)
      )

      localStorage.setItem(
        'nu-global-header-featured-links',
        JSON.stringify(this.featuredLinks)
      )

      localStorage.setItem(
        'nu-global-header-pinned-link',
        JSON.stringify(this.pinnedLink)
      )
    },
  }
}
