import header from './components/header'
import footer from './components/footer'
import trustarc from './components/trustarc'

const NUGlobalElements = {
  header,
  footer,
  trustarc,
}

window.NUGlobalElements = NUGlobalElements

export default NUGlobalElements
