import Header from '../../html/header.html'
import Footer from '../../html/footer.html'

export function getTemplate(fileName) {
    if (!fileName) { return false }

    let template = ''

    switch (fileName) {
        case 'header': template = Header
            break;
        case 'footer': template = Footer
            break;
    }

    return template;
};