import { createClient } from 'contentful/dist/contentful.browser.min.js'
import { getTemplate } from '../util/getTemplate.js'
import { getIcon } from '../util/getIcon.js'

export default function footer() {
    return {
      client: null,
      supportLinks: [],
      campusLinks: [],
      socialLinks: [],
      copyright: `© ${new Date().getFullYear()} Northeastern University`,
      template: "",
      init() {
        this.client = createClient({
          space: '8276ycqoicis',
          environment: 'master',
          accessToken: 'A_Rrll-l9VF_CYkRwllZIxsp3GcISsXIpgiSeh7UDno',
          timeout: 3000,
        })

        this.getCachedData()

        this.updateData()

        this.getTemplate()
        
        this.$el.innerHTML = this.template
    },
    getCachedData() {
      if (!localStorage) {
        return
      }

      const campusLinks = localStorage.getItem('nu-global-footer-campus-links')

      if (campusLinks) {
        this.campusLinks = JSON.parse(campusLinks)
      }

      const socialLinks = localStorage.getItem('nu-global-footer-social-links')

      if (socialLinks) {
        this.socialLinks = JSON.parse(socialLinks)
      }

      const supportLinks = localStorage.getItem('nu-global-footer-support-links')

      if (supportLinks) {
        this.supportLinks = JSON.parse(supportLinks)
      }
    },
    updateData() {
      if (this.dataIsAlreadyCached()) {
        return
      }

      Promise.all(
        [
          this.getCampusLinks(),
          this.getSocialLinks(),
          this.getSupportLinks(),
        ]
      ).then(() => {
        this.cacheData()
      })
    },
    dataIsAlreadyCached() {
      const cachedAt = localStorage.getItem('nu-global-footer-cached-at')

      if (!cachedAt) {
        return false
      }

      const cacheFor = 10 * 60 * 1000 // 10 minutes

      return Date.now() - cachedAt < cacheFor
    },
    cacheData() {
      if (!localStorage) {
        return
      }

      localStorage.setItem('nu-global-footer-cached-at', Date.now().toString())

      localStorage.setItem(
        'nu-global-footer-campus-links',
        JSON.stringify(this.campusLinks)
      )

      localStorage.setItem(
        'nu-global-footer-social-links',
        JSON.stringify(this.socialLinks)
      )

      localStorage.setItem(
        'nu-global-footer-support-links',
        JSON.stringify(this.supportLinks)
      )
    },
    getIcon(icon) {
      return getIcon(icon)
    },
    getTemplate() {
      this.template = getTemplate('footer')
    },
    getCampusLinks(campusLinks = []) {
      return this.client
        .getEntries({
          content_type: 'globalCampusLinks',
          order: 'fields.order',
        })
        .then((entries) => {
          this.campusLinks = entries.items.map(({ sys, fields }) => {
            return {
              id: sys.id,
              text: fields.text,
              url: fields.url,
              order: fields.order,
              isFlagship: fields.isFlagship
            }
          })
        })
    },
    getSocialLinks() {
      return this.client
        .getEntries({
          content_type: 'globalFooterSocialLinks',
          order: 'fields.order',
        })
        .then((entries) => {
          this.socialLinks = entries.items.map(({ sys, fields }) => {
            return {
              id: sys.id,
              text: fields.text,
              icon: fields.icon,
              url: fields.url,
              order: fields.order
            }
          })
        })
    },
    getSupportLinks() {
      return this.client
        .getEntries({
          content_type: 'globalFooterSupportLinks',
          order: 'fields.order',
        })
        .then((entries) => {
          this.supportLinks = entries.items.map(({ sys, fields }) => {
            return {
              id: sys.id,
              text: fields.text,
              url: fields.url,
              order: fields.order
            }
          })
        })
    }
  }
}
