import Facebook from '../../icons/facebook.html';
import Instagram from '../../icons/instagram.html';
import Linkedin from '../../icons/linkedin.html';
import Snapchat from '../../icons/snapchat.html';
import Tiktok from '../../icons/tiktok.html';
import Twitter from '../../icons/twitter.html';
import X from '../../icons/x.html';
import Youtube from '../../icons/youtube.html';

export function getIcon(iconName) {
    if (!iconName) { return false }

    let icon = ''

    switch (iconName) {
        case 'facebook': 
            icon = Facebook
            break;
        case 'instagram':
            icon = Instagram
            break;
        case 'linkedin':
            icon = Linkedin
            break;
        case 'snapchat':
            icon = Snapchat
            break;
        case 'tiktok':
            icon = Tiktok
            break;
        case 'twitter':
            icon = Twitter
            break;
        case 'x':
            icon = X
            break;
        case 'youtube':
            icon = Youtube
            break;
    }

    return icon;
};